.primeNg-inputs {
  height: 32px;
  border: 1px solid #ebeff2;
  border-radius: 4px !important;
  font-size: 11px !important;
}

.primeNg-inputs-list-header {
  height: 38px;
  border: 1px solid #ebeff2;
  border-radius: 4px !important;
  font-size: 13px !important;
}
