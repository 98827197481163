@media (max-width: 768px) {
  .signup-detail {
    .page-size {
      padding: 20px !important;
    }
  }

  .enrolle-account {
    padding-left: 0;
    text-align: left;
  }

  .googleMapMedia {
    margin-top: 0 !important;
  }
  .trip-info-tabView {
    display: inline;
  }
}

@media (max-width: 767px) {
}

@media (min-width: 768px) {
}
