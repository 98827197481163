/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #dee2e6; /* Background color of the scrollbar thumb */
  border-radius: 5px; /* Border radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #c5ced7; /* Background color of the scrollbar thumb on hover */
}
