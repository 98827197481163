.p-dialog {
  &.custom-filter-mobile {
    transition: none;
    transform: none;
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    max-height: 100%;
    height: 100%;

    .p-dialog-header {
      border-bottom: 1px solid #ebeff2;
      background-color: white;
    }
    .p-dialog-footer {
      padding-top: 1.5rem;
      border-top: 1px solid #ebeff2;
    }
    .p-dialog-header-close-icon {
      width: 25px;
      height: 25px;
    }
    .p-dialog-header-maximize {
      display: none;
      pointer-events: none;
    }
    .tab-calendar {
      .p-inputwrapper {
        .p-inputtext {
          border: 0;
          font-size: 13px;
          font-weight: 500;
          color: #2c71c3;
          width: 36px;
          padding-right: 0;
        }
        .mobile-screen-calendor {
          width: 0 !important;
        }
      }
    }
  }
}
